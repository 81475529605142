@import "styles/variables.module";

.formTable {
	margin-left: auto;
	margin-right: auto;

	h3 {
		margin: 0;
	}

	table {
		width: 100%;
	}

	.formTableHeader {
		display: table;
		width: 100%;
		min-height: 52px;
		padding: 0;
		margin: 0;
		background: $calc;

		.ant-form-item {
			margin: 0;
		}

		td {
			font-weight: 700;
		}
	}

	.formTableRow {
		display: table;
		width: 100%;
		min-height: 80px;
		border-bottom: 1px solid $calc;
		gap: 16px;
	}

	.formTableCol {
		padding: 8px 16px;
	}

	.formTableSelectBox {
	}

	.formTableSelect {
		font-weight: 400;

		&.ant-select {
			.ant-select-selector {
				min-width: 60px;
			}
		}

		& > div {
			min-width: 65px;
		}

		& > span,
		& > b {
			margin-right: 4px;
		}

		&:last-of-type {
			margin-top: 4px;
			margin-bottom: 0;
		}
	}

	.formTableSubFooter {
		display: table;
		min-height: 50px;
	}

	.formTableFooter {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0;
		width: 100%;
		background: $calc;

		.ant-form-item {
			margin: 0;
		}
	}

	.formTableFootRow {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		min-height: 56px;
	}

	.width {
		width: 300px;
	}

	.deleteButton {
		&:hover svg path {
			fill: $red;
		}
	}

	.contentLeft {
		text-align: left;
	}

	.contentRight {
		text-align: right;
	}

	.contentCenter {
		text-align: center;
	}

	.previewBox {
		position: relative;
		cursor: pointer;

		canvas {
			margin: 0 auto;
		}
	}

	.dxfSVG {
		width: 100%;

		svg {
			max-height: 100px;
			text-align: center;
		}

		svg {
			scale: 0.9;
		}
	}
}

.noData {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 355px;
}

.modal {
	svg {
		scale: 0.95;
		text-align: center;
	}
}
