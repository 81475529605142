.box {
	display: flex;
	text-align: center;
	flex-direction: column;
	margin-top: 50px;
	gap: 16px;

	a {
		display: block;
	}

	p {
		margin: 0;
	}
}

.link {
	display: flex;
	align-self: center;
	transition: all 0.2s ease;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: #4096ff;
	}
}
