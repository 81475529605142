.root {
  display: flex;
  flex-direction: column;
}

.dragger .draggerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}

.dragger .draggerContent button {
  text-transform: uppercase;
}
