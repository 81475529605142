@import "../../styles/variables.module";

.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;


  .fullWidth {
    max-width: 100%;
    margin: 40px 0;
  }
}
