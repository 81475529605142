.formWrapper {
	margin-top: 50px;
	justify-content: center;

	h3 {
		margin-bottom: 30px;
	}
}

.formItem {
	justify-content: center;
	margin-left: -200px;

	> div {
		justify-content: center;
		width: 100%;
	}
}

.formWrapper .ant-row.ant-form-item-row {
	display: none;
}

@media (max-width: 1024px) {
	.formItem {
		margin-left: 0;
	}
}
