@import "styles/variables.module.scss";

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;

  header {
    padding: 24px;
    a {
      display: block;
      width: 100px;
    }
  }

  .headerInner {
    display: flex;
    justify-content: space-between;
  }
}

.main {
  position: relative;
  height: 100%;
}


