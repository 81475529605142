/* Colors */
$black: #000;
$darkGray3: #39393B;
$darkGray2: #5C5C60;
$darkGray1: #9D9DA1;
$gray: #C4C4C8;
$lightGray: rgba(51, 51, 51, 0.2);
$lightGray1: #DEDDE1;
$lightGray2: #EBEBED;
$lightGray3: #F8F8F8;
$white: #FFFFFF;
$darkBlue1: #001699;
$blue1: #001DCC;
$darkBlue: #262834;
$lightBlue: #99A7FF33;
$lightBlue1: #99A7FF;
$lightBlue2: #7A8DFF;
$lightBlue3: #f8f9fc;
$lightBlue4: #d4daed;
$darkBlue2: #212B3C;
$green: #52C41A;
$red: #FF4D4F;
$orange: #FAAD14;
$yellow: #F2C94C;
$mintGreen: #3EEBD0;

$main: $black;
$text: $black;
$calc: $lightGray;
$iconColor: #c3cdf3;

/* Sizes */
$spacing: 15px;


