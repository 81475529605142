.root {
	width: 100%;
	height: auto;
	margin: auto;
}

body {
	font-family: "Cinzel", serif;
	font-family: "Roboto", sans-serif;
}

.imgWrapper {
	margin-top: 40px;
}

.img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 160px;
}

.titleWrapper {
	text-align: center;
	width: 100%;
}

.informationWrapper {
	text-align: center;
	margin: 0 auto 80px;
	max-width: 500px;
}

.information {
	color: #454545;
	font-weight: 700;
	line-height: 25px;
}

.comebackLinkWrapper {
	text-align: center;
}

.comebackLink {
	text-decoration: none;
	color: gray;
	font-weight: bold;
}

.link {
	transition: all 0.2s ease;
	cursor: pointer;

	&:hover {
		color: #4096ff;
	}
}
